import { AuthService } from 'app/services/auth.service';
import { FirebaseService } from './../../services/firebase.service';
import { AuthState } from 'app/interfaces/auth.interfaces';
import { AppState } from './../../store/reducers/app.reducer';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Component, OnInit, HostListener, HostBinding, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { WINDOW_PROVIDERS, WINDOW } from './../../shared/helpers/window.helper';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { SigninComponent } from '../signin/signin.component';
import { SignupComponent } from '../signup/signup.component';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isFixed;
  public authState$: Observable<AuthState>;

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private store: Store<AppState>,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window
  ) { }

  ngOnInit() {
    this.authState$ = this.store.select('auth').pipe(shareReplay());
  }
  @HostListener("window:scroll", [])
  onWindowScroll() {
    const offset = this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
    if(offset > 10) {
      this.isFixed = true
    } else {
      this.isFixed = false;
    }
  }

  @HostBinding("class.menu-opened") menuOpened = false;
  toggleMenu() {
    this.menuOpened = !this.menuOpened
  }

  openSigninPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(SigninComponent, dialogConfig);
  }

  openSignupPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(SignupComponent, dialogConfig);
  }

  doLogout() {
    this.authService.Logout();
  }

}
