import { AuthService } from './../../services/auth.service';
import { NotificationService } from './../../services/notifications.service';
import { Actions, ofType } from '@ngrx/effects';
import * as AuthActions from './../../store/actions/auth.actions';
import { Component, OnInit, ViewChild, ElementRef, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
// import * as AppActions from './../../store/actions';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit, OnDestroy {
  private successSubcription: Subscription;
  private errorSubcription: Subscription;
  public hide: boolean;
  public loginForm: FormGroup;
  public loginProcess = false;
  @ViewChild('saveButton', {read: ElementRef}) saveButton: ElementRef;
  @ViewChild('loginGoogleButon') loginGoogleButton: ElementRef;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private storeActions$: Actions,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SigninComponent>,
    ) {
      let email = '';
      let password = '';

      // Creación del formulario que se mostrará en el template
      this.loginForm = fb.group({
        email: new FormControl(email, [Validators.required, Validators.email]),
        password: new FormControl(password, [Validators.required, Validators.minLength(5)])
      });
    }
  ngOnInit() {
    // Nos subscribimos al canal del store que nos informa de un login correcto
    this.successSubcription = this.storeActions$.pipe(
      ofType(AuthActions.SIGNIN_SUCCESS),
    ).subscribe(() => {
      this.loginProcess = false;
      this.close();
    });

    this.errorSubcription = this.storeActions$.pipe(
      ofType(AuthActions.SIGNIN_ERROR),
      map((action: AuthActions.SigninError) => action.payload.error)
    ).subscribe((error) => {
      this.loginProcess = false;
      // TODO realizar diferentes acciones en función del errror.code: 'auth/user-not-found'
      this.notificationService.error(error.message);
    });
  }
  doLogin() {
    if (!this.loginProcess) {
      this.loginProcess = true;
      this.authService.Login(this.loginForm.value);
    }
  }
  onGoogleLogin() {
    this.authService.GoogleLogin();
  }
  close() {
    this.dialogRef.close();
  }
  ngOnDestroy() {
    this.successSubcription.unsubscribe();
    this.errorSubcription.unsubscribe();
  }

}
