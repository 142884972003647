import { FirebaseService } from './firebase.service';
import { AppState } from 'app/store/reducers/app.reducer';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as AuthActions from './../store/actions/auth.actions';

@Injectable()
export class AuthService {
  constructor(
    private store: Store<AppState>,
    private firebaseService: FirebaseService
  ) {

  }

  public LoginFailed(err) {
    // Codigos de error del login de firebase
    switch(err.code) {
      case 'auth/user-not-found':
        // TODO translate message
        err.message = 'User dont exists';
        break;
      case 'auth/wrong-password':
        // TODO translate message
        err.message = 'User exists but password is wrong';
        break;
      default:
        break;
    }
    this.store.dispatch(new AuthActions.SigninError({error: err}));
    this.store.dispatch(new AuthActions.SigninProcessComplete({success: false}));
  }

  public Logout() {
    // this.firebaseService.logoutUser();
    this.store.dispatch(new AuthActions.Logout());
  }

  public Login(params) {
    // Emitimos la acción de realizar login
    this.store.dispatch(new AuthActions.TrySignin(params));
  }

  public Signup(params) {
    // Emitimos la acción de realizar login
    this.store.dispatch(new AuthActions.TrySignup(params));
  }

  public GoogleLogin() {
    this.firebaseService.doGoogleLogin().then(
      (user) => {
        // console.log('Google login user response: ', user);
        this.store.dispatch(new AuthActions.SigninSuccess({user: user.user}));
        this.store.dispatch(new AuthActions.SigninProcessComplete({success: true}));
      }
    );
  }
}
