import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/reducers/app.reducer';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-items-list-explication',
  templateUrl: './items-list-explication.component.html',
  styleUrls: ['./items-list-explication.component.scss']
})
export class ItemsListExplicationComponent implements OnInit, OnDestroy {
  @Input() public items: Array<any>;

  public lang: string;
  public ngDestroyed$ = new Subject();
  
  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    this.store.select(state => state.utils.lang).pipe(
      takeUntil(this.ngDestroyed$)
    ).subscribe((lang: string) => {
      this.lang = lang;
    });
  }

  public ngOnDestroy() {
    this.ngDestroyed$.next();
  }

}
