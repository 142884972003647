import { AppState } from './../store/reducers/app.reducer';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class StoreService {
  private state: AppState;
  constructor(
    private store: Store<AppState>
  ) {}
  
  getLastSavedState() {
    return this.getActualAppState;
  }
  /**
   * @description Actualiza el valor guardado del estado de la aplicación
   */
  storeActualAppState(): void {
    this.getActualAppState().subscribe((state: AppState) => {this.state = state; });
  }
  /**
   * Nos devuelve un observable al que debremos subscribirnos para obtener el valor actual
   * del estado de nuestra app
   */
  getActualAppState(): Observable<AppState> {
    return this.store.pipe(take(1));
  }
}
