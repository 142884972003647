import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';

const PREFIX: string = environment.APP_LOCALSTORAGE_PREFIX;
/**
 * Servicio que se encarga de gestionar los datos guardados en el localstorage del navegador
 */
@Injectable()
export class LocalStorageService {
  /**
   * @static
   * Lo definimos como estático para que el servicio únicamente actue como recipiente de funciones
   * sin necesidad de guardar ninguna instancia en memoria
   */
  /*
    static loadInitialState() {
      // el método 'reduce' aplicado sobre un array acabará mostrando el resultado final
      // de iterar sobre ese array, recibe el acumulador y el valor del array value en la iteración actual
      return Object.keys(localStorage).reduce((state, storageKey) => {
        // Si la variable es de nuestra aplicación
        if(storageKey.includes(PREFIX)) {
          const stateKeys = storageKey
            // Quitamos el prefijo identificativo
            .replace(PREFIX, '')
            .toLowerCase()
        }
      });
    }
  */
  constructor() { }
  /**
   * Insertamos un valor en el localstorage
   */
  setItem(key: string, value: any) {
    localStorage.setItem(`${PREFIX}${key}`, JSON.stringify(value));
  }

  getItem(key: string) {
    return JSON.parse(localStorage.getItem(`${PREFIX}${key}`));
  }

  removeItem(key: string) {
    localStorage.removeItem(`${PREFIX}${key}`);
  }
  /**
   * Comprobamos si el localsotarge es accesible
   */
  testLocalStorage() {
    const testValue = 'testValue';
    const testKey = 'testKey';
    let retrievedValue: string;
    const errorMessage = 'localStorage did not return expected value';

    this.setItem(testKey, testValue);
    retrievedValue = this.getItem(testKey);
    this.removeItem(testKey);

    if (retrievedValue !== testValue) {
      throw new Error(errorMessage);
    }
  }

}
