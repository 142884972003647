import { NotificationService } from './../../services/notifications.service';
import { FirebaseService } from './../../services/firebase.service';
import { Observable } from 'rxjs';
// angular core
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// actions
import * as AuthActions from './../actions/auth.actions';
// RXJS imports
import { map, switchMap, mergeMap, tap, catchError } from 'rxjs/operators';
import { from } from 'rxjs';
// NGRX imports
import { Effect, Actions, ofType } from '@ngrx/effects';
// custom types
import * as authTypes from './../../types/auth.types';
import { AuthService } from 'app/services/auth.service';

import { DebugService, DebugLevel } from "app/services/debug.service";

/**
 * Cazamos al vuelo mediante '@Effect' las llamadas a algún cambio de estado antes de que sea procesado por el reducer
 *
 * El Effect emite finalmente una acción hacia el reducer a no ser que se incluya: '@Effect({dispatch: false})'
 *
 * Un effect deverá devolver siempre una o varias acciones ya que el flujo seguirá hacia el reducer correspondiente
 */
@Injectable()
export class Autheffects {

  /**
   * NOTE TRY_SIGNUP
   *  Intercepta la petición al app store para crear un nuevo usuario
   *
   *  Contiene la lógica de creación del nuevo usuario (en este caso mediante firebase)
   *
   *  Intercepta la acción
   *
   *  Devuelve dos acciones: SIGNUP y SET_TOKEN
   */
  @Effect()
  authSignup = this.actions$.pipe(
    // Interceptamos solo un intento de crear usuario nuevo
    ofType(AuthActions.TRY_SIGNUP),
    // Filtramos el stream para avanzar en el chain solo con el payload
    map((action: AuthActions.TrySignup): authTypes.firebasePayload => {
      this.debugService.debug(DebugLevel.TRACE, 'TrySignup action intercepted !');
      return action.payload;
    }),
    // En adelante usamos switchmap para prevenir que se actualize siempre al último observable de firebase
    switchMap((authData: authTypes.firebasePayload): Observable<firebase.auth.UserCredential> => {
      // Convertimos la Promise devuelta por firebase en un observable
      return this.firebaseService.createNewUser(authData);
    }),
    switchMap(
      (user: firebase.auth.UserCredential) => {
        // Signup successfully
        this.debugService.debug(DebugLevel.INFO, 'user que nos llega al switchmap de signup: ', user);
        return [
          { type: 'SIGNIN_SUCCESS', payload: { user: user.user } },
        ];
      }
    ),
    catchError((err, caught) => {
      // Login failed
      this.authService.LoginFailed(err);
      return caught;
    })
  );
  /**
   * NOTE TRY_SIGNIN
   * Intercepta el intento de login
   *
   * Devuelve dos acciones: SIGNIN y SET_TOKEN
   */
  @Effect()
  authSignIn: Observable<any> = this.actions$.pipe(
    ofType(AuthActions.TRY_SIGNIN),
    map((action: AuthActions.TrySignin): authTypes.firebasePayload => {
      return action.payload;
    }),
    switchMap((loginParams: authTypes.firebasePayload): Observable<firebase.auth.UserCredential> => {
      return this.firebaseService.signinUser(loginParams);
    }),
    switchMap(
      (user: firebase.auth.UserCredential) => {
        // Login successfully
        this.debugService.debug(DebugLevel.INFO, 'user que nos llega al switchmap: ', user);
        const receivedUser = user.user.email ? user.user : user;
        return [
          { type: 'SIGNIN_SUCCESS', payload: { user: receivedUser } },
          { type: 'SIGNIN_PROCESS_COMPLETE', payload: { success: true } }
        ];
      }
    ),
    catchError((err, caught) => {
      // Login failed
      this.authService.LoginFailed(err);
      return caught;
    })
  );

  /**
   * Captura los errores en el login/register de usuario
   */
  @Effect({ dispatch: false })
  signinError = this.actions$.pipe(
    ofType(AuthActions.SIGNIN_ERROR),
    switchMap((action: AuthActions.SigninError) => {
      return [action.payload.error];
    }),
    tap((err) => {
      this.debugService.debug(DebugLevel.ERROR, 'Singin Process Error: ', err);
    })
  );

  @Effect()
  signinSuccess = this.actions$.pipe(
    ofType(AuthActions.SIGNIN_SUCCESS),
    switchMap((action: AuthActions.SigninSuccess) => {
      const AppUser = {
        isAnonymous: action.payload.user.isAnonymous,
        given_name: action.payload.user.displayName,
        email: action.payload.user.email,
        emailVerified: action.payload.user.emailVerified,
        photoURL: action.payload.user.photoURL ? action.payload.user.photoURL : 'https://3.bp.blogspot.com/-qDc5kIFIhb8/UoJEpGN9DmI/AAAAAAABl1s/BfP6FcBY1R8/s1600/BlueHead.jpg',
        uid: action.payload.user.uid,
        isNewUser: false
      };
      this.router.navigate(['/admin']);
      this.debugService.debug(DebugLevel.TRACE, 'SIGNIN_SUCCESS payload received: ', action.payload)
      return [{ type: 'SET_APP_USER', payload: AppUser }];
    })
  );

  /**
   * NOTE LOGOUT
   * No se llega a llamar a ningún reducer, se corta la transmisión de la action
   */
  @Effect({ dispatch: false })
  authLogout = this.actions$.pipe(
    ofType(AuthActions.LOGOUT),
    switchMap((action: AuthActions.Logout) => {
      this.debugService.debug(DebugLevel.DEBUG, 'Realizamos el logout de firebase', action);
      return this.firebaseService.logoutUser();
    }),
    tap(() => {
      this.router.navigate(['/']);
    })
  );

  constructor(
    // Un observable que va devolviendo las acciones emitidas por la app
    private actions$: Actions,
    private authService: AuthService,
    private debugService: DebugService,
    private router: Router,
    private firebaseService: FirebaseService
  ) { }

}
