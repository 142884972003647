import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AuthGuard } from './services/auth-guard.service';

export const appRoutes: Routes = [
  // SECTION public routes
  {
    path: 'admin',
    loadChildren: './edit/edit.module#EditModule'
  },
  {
    path: '',
    redirectTo: "web/home",
    pathMatch: 'full'
  },
  {
    path: 'web', component: AppComponent, children: [

      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'solutions',
        loadChildren: './pages/serveis/serveis.module#ServeisModule'
      },
      {
        path: 'sectors',
        loadChildren: './pages/activitats/activitats.module#ActivitatsModule'
      },
      {
        path: 'about',
        loadChildren: './pages/about/about.module#AboutModule'
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
