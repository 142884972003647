import { Observable } from 'rxjs/Observable';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { switchMap, take } from 'rxjs/operators';
import { AppState } from 'app/store/reducers/app.reducer';
import { AuthState } from 'app/interfaces/auth.interfaces';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // the process request continues
    return this.store.select('auth').pipe(
      take(1),
      switchMap((authState: AuthState) => {
        const copiedRequest = req.clone({
          // headers: req.headers.append('', ''),
          // Insertamos el auth token en todas las llamadas salientes
          // TODO Diferenciar entre los posibles dominios a los que podemos llamar
          params: req.params.set('auth', authState.token)
        });
        return next.handle(copiedRequest);
      })
    );
  }
}
