import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortfolioCarouselComponent } from './portfolio-carousel.component';
import { SharedMaterialModule } from 'app/shared/shared-material.module';
import { TranslateModule } from '@ngx-translate/core';
import { NguCarouselModule } from '@ngu/carousel';
import { BottomSheetShareComponent } from 'app/shared/components/bottom-sheet-share/bottom-sheet-share.component';
import { OrderModule } from 'ngx-order-pipe';


@NgModule({
  declarations: [BottomSheetShareComponent, PortfolioCarouselComponent],
  imports: [
    CommonModule,
    SharedMaterialModule,
    TranslateModule,
    NguCarouselModule,
    OrderModule
  ],
  entryComponents: [BottomSheetShareComponent],
  exports: [PortfolioCarouselComponent, SharedMaterialModule, NguCarouselModule]
})
export class PortfolioCarouselModule { }
