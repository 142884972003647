import { Injectable, OnInit } from "@angular/core";
import { FirebaseService } from "./firebase.service";
import { NotificationService } from "./notifications.service";
import { AngularFirestoreCollection } from "@angular/fire/firestore";
import { Product, DatabaseProduct } from "app/interfaces/product.interfaces";
import { Observable } from "rxjs";
import { DebugLevel, DebugService } from "./debug.service";

@Injectable()
export class ProductService implements OnInit {
    private firebaseCollectionRef: AngularFirestoreCollection;
    public products$: Observable<Product[]>;
    public productsData$: Observable<DatabaseProduct[]>;

    constructor(
        private firebaseService: FirebaseService,
        private notificationService: NotificationService,
        private debugService: DebugService
    ) {
        this.debugService.debug(DebugLevel.INFO, 'initializing database connection to products collection');
        // Iniciamos la conexión a la database donde guardamos los productos
        this.firebaseCollectionRef = this.firebaseService.getCollectionReference('product');
        this.products$ = this.firebaseService.getCollectionValueChanges(this.firebaseCollectionRef);
        this.productsData$ = this.firebaseService.getCollectionSnapshotChanges(this.firebaseCollectionRef);
    }
    /**
     * @function fetchProducts
     * @description
     */
    public fetchProducts(type = null): Observable<Product[] | DatabaseProduct[]> {
        switch (type) {
            case 'onlyData':
                return this.products$;
            case 'snapshot':
                console.log('vamo bien 1')
                return this.productsData$;
            default:
                return this.products$;
        }

    }

    ngOnInit() { }

    public addProduct(product: Product) {
        this.debugService.debug(DebugLevel.TRACE, 'ProductService->addProduct / init: ', product);
        this.firebaseService.addItem(product, 'products');
        this.debugService.debug(DebugLevel.TRACE, 'ProductService->addProduct / end: ', product);
    }

    public updateProduct(product: DatabaseProduct) {
        this.debugService.debug(DebugLevel.TRACE, 'ProductService->updateProduct / init: ', product);
        this.firebaseService.updateItem(product, 'products');
        this.debugService.debug(DebugLevel.TRACE, 'ProductService->updateProduct / end: ', '');
    }
}   