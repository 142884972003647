import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import * as ComponentIterfaces from './interfaces';


@Component({
  selector: 'app-titleimage',
  templateUrl: './titleimage.component.html',
  styleUrls: ['./titleimage.component.scss']
})
export class TitleimageComponent implements OnInit {
  @Input() public bgImage: string;
  @Input() public titleImage: string;
  @Input() public subtitle: string;
  @Input() public title: string;
  @Input() public options: ComponentIterfaces.Options;

  constructor() { }

  ngOnInit() {
    if(!this.options.bgPosition) this.options.bgPosition = ComponentIterfaces.ImagePositions.left;
  }

}
