import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogosComponent } from './logos.component';
import { SharedMaterialModule } from 'app/shared/shared-material.module';

@NgModule({
  declarations: [LogosComponent],
  imports: [
    CommonModule,
    SharedMaterialModule
  ],
  exports: [
    LogosComponent
    
  ]
})
export class LogosModule { }
