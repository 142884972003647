// Core
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpModule } from '@angular/http';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/auth';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule
} from '@angular/common/http';

// API
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';


// Custom components
import { AppRoutingModule } from './app.routes';
import { AppComponent } from './app.component';
import { SigninComponent } from './pages/signin/signin.component';
import { HeaderComponent } from './pages/header/header.component';
import { FooterComponent } from './pages/footer/footer.component';
import { ServicesCarouselComponent } from './views/home/services-carousel/services-carousel.component';
import { DemoComponent } from './views/home/demo/demo.component';
import { SignupComponent } from './pages/signup/signup.component';
import { HomeComponent } from './pages/home/home.component';

// Custom services
import { ServicesComponent } from './views/home/services/services.component';
import { NotificationService } from './services/notifications.service';
import { StoreService } from './services/store.service';
import { Autheffects } from './store/effects/auth.effects';
import { LocalStorageService } from './services/localstorage.service';
import { FirebaseService } from './services/firebase.service';
import { HttpErrorInterceptor } from './interceptors/error.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AuthGuard } from './services/auth-guard.service';
import { AppErrorHandler } from './services/error-handle.service';
import { AuthService } from './services/auth.service';
import { DebugService } from './services/debug.service';
import { ProductService } from './services/product.service';
import { LangService } from './services/lang-service';

// Redux store
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { reducers } from './store/reducers/app.reducer';
import { ProductEffects } from './store/effects/product.effects';
import { UtilsEffects } from './store/effects/utils.effects';
import { ContentEffects } from './store/effects/content.effects';
import { productReducer } from './store/reducers/product.reducer';

// Shared
import { SharedMaterialModule } from './shared/shared-material.module';
import { AppLoaderService } from './shared/services/app-loader/app-loader.service';
import { environment } from 'environments/environment';
import { SharedDirectivesModule } from './shared/directives/shared-directives.module';
import { AppLoaderComponent } from './shared/services/app-loader/app-loader.component';
import { WINDOW_PROVIDERS } from './shared/helpers/window.helper';
import { BgImageModule } from './shared/components/bgimage/bgimage.module';
import { PortfolioCarouselModule } from './shared/components/portfolio-carousel/portfolio-carousel.module';
import { SelectItemAddClassDirective } from './shared/directives/select-item.directive';
import { TitleimageModule } from './shared/components/titleimage/titleimage.module';
import { LogosModule } from './shared/components/logos/logos.module';
import { RoundedIconsModule } from './shared/components/rounded-icons/rounded-icons.module';
import { ItemsListExplicationModule } from './shared/components/items-list-explication/items-list-explication.module';
import { NgxSortableModule } from 'ngx-sortable';

// Translate utils
import {
  TranslateModule,
  TranslateLoader,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BetterHighlightDirective } from './shared/directives/hover-item.directive';
import { ContentItemsModule } from './shared/components/content-items/content-items.module';
import { OrderModule } from 'ngx-order-pipe';
import { RootComponent } from './root.component';
import { BgVideoModule } from './shared/components/bgvideo/bgvideo.module';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './../assets/i18n/', '.json');
}

@NgModule({
  imports: [
    // shared
    CommonModule,
    AngularFireAuthModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    FlexLayoutModule,
    OrderModule,
    BrowserModule,
    SharedDirectivesModule,
    BrowserAnimationsModule,
    HttpModule,
    StoreModule.forRoot(reducers),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([Autheffects, ProductEffects, UtilsEffects, ContentEffects]),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
    AngularFireDatabaseModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
    SharedMaterialModule,
    // Component modules imported
    BgImageModule,
    BgVideoModule,
    TitleimageModule,
    PortfolioCarouselModule,
    ContentItemsModule,
    LogosModule,
    RoundedIconsModule,
    ItemsListExplicationModule
  ],
  declarations: [
    // Common components
    AppComponent,
    RootComponent,
    HeaderComponent,
    FooterComponent,
    SigninComponent,
    SignupComponent,
    // Page components
    HomeComponent,
    AppLoaderComponent,
    ServicesComponent,
    // Utils components
    ServicesCarouselComponent,
    DemoComponent,
    // Common directives
    SelectItemAddClassDirective,
    BetterHighlightDirective
  ],
  providers: [
    ProductService,
    AppLoaderService,
    TranslateService,
    AuthService,
    AuthGuard,
    LangService,
    StoreService,
    FirebaseService,
    DebugService,
    LocalStorageService,
    AppErrorHandler,
    NotificationService,
    WINDOW_PROVIDERS,
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [RootComponent],
  entryComponents: [SigninComponent, SignupComponent, AppLoaderComponent, HeaderComponent, AppComponent]
})
export class AppModule { }
