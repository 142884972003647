import { Directive, OnInit, Renderer2, ElementRef, HostListener, HostBinding } from '@angular/core';
import { Event } from '@angular/router';

@Directive({
  selector: '[appBetterHighlight]'
})
export class BetterHighlightDirective implements OnInit {
  @HostBinding('style.backgroundColor') backgroundColor: string; 
  @HostBinding('style.color') textColor: string;
  /**
   * @param elRef Se trata de un acceso al elemento del DOM. A través de nativeelement property
   * se accede al elemento DOM original
   * @param renderer
   */
  constructor(private elRef: ElementRef, private renderer: Renderer2) { }

  ngOnInit() { }
  // los host listeners son sensibles a los eventos típicos de los elementos HTML:
  // mouseenter,  mouseleave, ...
  @HostListener('mouseenter') mouseover(eventData: Event) {
    // this.renderer.setStyle(this.elRef.nativeElement, 'backgroundColor', 'blue');
    this.backgroundColor = '#ddd';
  }
  @HostListener('mouseleave') mouseleave(eventData: Event) {
    // this.renderer.setStyle(this.elRef.nativeElement, 'backgroundColor', 'transparent');
    this.backgroundColor = 'transparent';
  }

}
