import { TitleimageComponent } from './titleimage.component';
import { SharedMaterialModule } from '../../shared-material.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [TitleimageComponent],
  imports: [
    CommonModule,
    SharedMaterialModule
  ],
  exports: [TitleimageComponent]
})
export class TitleimageModule { }
