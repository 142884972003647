import * as ContentState from "app/interfaces/content.interfaces";
import * as  ContentActions from "app/store/actions/content.actions";
const initialState: ContentState.Content = {}

export function contentReducer(state = initialState, action) {
    switch (action.type) {
        // SET_CONTENTS
        case (ContentActions.SET_CONTENTS):
            return {
                ...state,
                content: action.payload[0]
            }
        default:
            return state;
    }
}