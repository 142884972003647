import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-content-items',
  templateUrl: './content-items.component.html',
  styleUrls: ['./content-items.component.scss']
})
export class ContentItemsComponent implements OnInit {
  @Input() public lang: string;
  @Input() public text: string;
  @Input() public items: any;
  @Input() public options?: Array<any>;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    if (this.items === undefined) {
      this.items = [];
    }
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  public itemsExist() { return this.items && this.items.length; }

}
