import { ProductState } from "app/interfaces/product.interfaces";
import * as  ProductActions from "app/store/actions/product.actions";
const initialState: ProductState = {
    products: []
}

export function productReducer(state = initialState, action) {
    switch (action.type) {
        // SET_PRODUCTS
        case (ProductActions.SET_PRODUCTS):
            return {
                ...state,
                products: [...action.payload]
            }
        // ADD_PRODUCT
        case (ProductActions.ADD_PRODUCT):
            return {
                ...state,
                products: [...state.products, action.payload]
            }
        // UPDATE_PRODUCT
        case (ProductActions.UPDATE_PRODUCT):
            const product = state.products[action.payload.index];
            const updatedProduct = {
                ...product,
                ...action.payload.updatedProduct
            };
            const products = [...state.products];
            products[action.payload.index] = updatedProduct;
            return {
                ...state,
                products
            };
        default:
            return state;
    }
}