import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedMaterialModule } from 'app/shared/shared-material.module';
import { RoundedIconsComponent } from './rounded-icons.component';

@NgModule({
  declarations: [RoundedIconsComponent],
  imports: [
    CommonModule,
    SharedMaterialModule
  ],
  exports: [RoundedIconsComponent]
})
export class RoundedIconsModule { }
