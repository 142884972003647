export const newItems = [
  {
    description: {
      ca: "Coneix la nostra nova representada",
      en: "Discover our new partner",
      es: "Conoce a nuestra nueva representada"
    },
    img: {
      src: "assets/images/hicof.logo.png",
      alt: "servicio técnico oficial de hicof"
    },
    link: " https://www.hicof.com/", // "https://www.youtube.com/watch?v=QW_3XWqkhMI"
    title: {
      ca: "Hicof",
      en: "Hicof",
      es: "Hicof"
    }
  },

  {
    description: {
      ca: "Col·laboradora reconeguda per Belimed Life Science",
      en: "Recognized partner for Belimed life science",
      es: "Colaboradora reconocida por Belimed Life Science"
    },
    img: {
      src: "assets/images/belimed.logo.png",
      alt: "servicio técnico oficial de belimed"
    },
    link: "http://www.belimed-lifescience.com",
    title: {
      ca: "Belimed",
      en: "Belimed",
      es: "Belimed"
    }
  },
  {
    description: {
      ca: "Servei tècnic oficial per a les representades de Comaq Casol",
      en: "Official technic support for Comaq Casol partners",
      es: "Servicio técnico oficial para las representadas de Comaq Casol"
    },
    img: {
      src: "assets/images/comaq.png",
      alt: "servicio técnico oficial de comaq casol"
    },
    link: "http://www.comaq-casol.com/",
    title: {
      ca: "Comaq Casol",
      en: "Comaq Casol",
      es: "Comaq Casol"
    }
  }
];
