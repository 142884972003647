import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedMaterialModule } from 'app/shared/shared-material.module';
import { ItemsListExplicationComponent } from './items-list-explication.component';

@NgModule({
  declarations: [ItemsListExplicationComponent],
  imports: [
    CommonModule,
    SharedMaterialModule
  ],
  exports: [ItemsListExplicationComponent]
})
export class ItemsListExplicationModule { }
