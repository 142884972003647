import { NgModule } from '@angular/core';

import { FontSizeDirective } from './font-size.directive';
import { ScrollToDirective } from './scroll-to.directive';


const directives = [
  FontSizeDirective,
  ScrollToDirective
]

@NgModule({
  imports: [],
  declarations: directives,
  exports: directives
})
export class SharedDirectivesModule {}
