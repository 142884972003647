import { Content, Section } from "app/interfaces/content.interfaces";
import { Action } from "@ngrx/store";

// Acciones REST
export const FETCH_CONTENTS = "FETCH_CONTENTS";
export const SET_CONTENTS = "SET_CONTENTS";
export const TRY_EDIT_CONTENT = "TRY_EDIT_CONTENT";
export const EDIT_CONTENT = "EDIT_CONTENT";
export const TRY_ADD_CONTENT = "TRY_ADD_CONTENT";
export const TRY_REMOVE_CONTENT = "TRY_REMOVE_CONTENT";
export const STORE_CONTENTS = "STORE_CONTENTS";
export const TRY_UPDATE_CONTENT = "TRY_UPDATE_CONTENT";
export const TRY_UPDATE_SECTION = "TRY_UPDATE_SECTION";
export const TRY_UPDATE_ITEM = "TRY_UPDATE_ITEM";
export const UPDATE_ITEM = "UPDATE_ITEM";
export const UPDATE_CONTENT = "UPDATE_CONTENT";
export const UPDATE_SECTION = "UPDATE_SECTION";
export const ADD_CONTENT = "ADD_CONTENT";
export const REMOVE_CONTENT = "REMOVE_CONTENT";
export const ADDED_CONTENT = "ADDED_CONTENT";

export class SetContents implements Action {
    readonly type = SET_CONTENTS;
    constructor(public payload: object) { }
}
/**
 * Recuperamos todos los contenidos de la página
 */
export class FetchContents implements Action {
    readonly type = FETCH_CONTENTS;
}

/**
 * @payload {index, updatedContent}
 */
export class TryUpdateContent implements Action {
    readonly type = TRY_UPDATE_CONTENT;
    constructor(public payload: { page: string, section: string, itemIndex: number, updatedContent: Content, field: string }) { }
}
/**
 * @payload {page, section, itemIndex, updatedContent}
 */
export class TryUpdateItem implements Action {
    readonly type = TRY_UPDATE_ITEM;
    constructor(public payload: { page: string, section: string, itemIndex: number, updatedContent: Section }) { }
}

/**
 * @payload {page, section, updatedContent}
 */
export class TryUpdateSection implements Action {
    readonly type = TRY_UPDATE_SECTION;
    constructor(public payload: { page: string, section: string, updatedContent: Section }) { }
}
/**
 * @payload {CONTENT}
 */
export class UpdateContent implements Action {
    readonly type = UPDATE_CONTENT;
}
/**
 * @payload {CONTENT}
 */
export class UpdateSection implements Action {
    readonly type = UPDATE_SECTION;
    constructor(public payload: { page: string, section: string, updatedSection: Section }) { }
}
/**
 * @payload {CONTENT}
 */
export class UpdateItem implements Action {
    readonly type = UPDATE_ITEM;
    constructor(public payload: { updatedContent: Section }) { }
}
/**
 * @payload {CONTENT}
 */
export class TryAddContent implements Action {
    readonly type = TRY_ADD_CONTENT;
    constructor(public payload: { page: string, section: string, updatedItems: any }) { }
}

export class EditContent implements Action {
    readonly type = EDIT_CONTENT;
    constructor(public payload: { item: 'string', content: string }) { }
}
export class TryRemoveContent implements Action {
    readonly type = TRY_REMOVE_CONTENT;
    constructor(public payload: { page: string, section: string, itemIndex: number }) { }
}

export class AddContent implements Action {
    readonly type = ADD_CONTENT;
    constructor(public payload: { content: Content }) { }
}

export class ContentAdded implements Action {
    readonly type = ADDED_CONTENT;
    constructor(public payload: { content: Content }) { }
}

export class RemoveContent implements Action {
    readonly type = REMOVE_CONTENT;
    constructor(public payload: { id: string }) { }
}

export class StoreContents implements Action {
    readonly type = STORE_CONTENTS;
}

export type ContentActions = ContentAdded | SetContents | RemoveContent | UpdateItem | UpdateSection | TryUpdateSection | TryUpdateItem | AddContent | UpdateContent | TryRemoveContent | TryAddContent | TryUpdateContent | FetchContents;