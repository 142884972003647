import { UtilsState } from "app/interfaces/utils.interfaces";
import * as UtilsActions from "../actions/utils.actions";

const initialState: UtilsState = {
    // Lenguaje por defecto al iniciar la app
    lang: "cat",
    appReady: false
}

export function utilsReducer(state = initialState, action: UtilsActions.UtilsActionTypes) {
    switch (action.type) {
        // LANG_SET
        case (UtilsActions.LANG_SET):
            return {
                ...state,
                lang: action.payload.lang
            }
        // READY_SET
        case (UtilsActions.READY_SET):
            console.log('Setting appready: ', action.payload.ready)
            return {
                ...state,
                appReady: action.payload.ready
            }
        default:
            return state;
    }
}