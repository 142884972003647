import { AppUser } from './../../interfaces/auth.interfaces';
import { Action } from '@ngrx/store';
/**
 * Definimos los nombres que identificarán inequívocamente a las acciones permitidas para 'Auth'
 */
// Acciones destinadas a ser interceptadas por effects
export const TRY_SIGNUP = 'TRY_SIGNUP';
export const TRY_SIGNIN = 'TRY_SIGNIN';
export const SET_APP_USER = 'SET_APP_USER';

// Acciones para informar a subscriptores
export const SIGNIN_ERROR = 'SIGNIN_ERROR';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_PROCESS_COMPLETE = 'SIGNIN_PROCESS_COMPLETE';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';

// Acciones principales de auth
export const SIGNUP = 'SIGNUP';
export const SIGNIN = 'SIGNIN';
export const LOGOUT = 'LOGOUT';

// Accion de localStorage
export const SET_TOKEN = 'SET_TOKEN';

/**
 * @description Acción que nos informa de que el usuario ha iniciado el proceso de registro
 */
export class TrySignup implements Action {
  readonly type = TRY_SIGNUP;
  constructor(public payload: {email: string, password: string}) {}
}

// Comment
/**
 * @description Acción que nos informa de que el usuario ha iniciado el proceso de login
 */
export class TrySignin implements Action {
  readonly type = TRY_SIGNIN;
  constructor(public payload: {email: string, password: string}) {}
}

/**
 * @description Acción que nos informa de que ha habido algún error en el proceso de login
 */
export class SigninError implements Action {
  readonly type = SIGNIN_ERROR;
  constructor(public payload: {error: any}) {}
}

/**
 * @description Acción que nos informa de que ha habido algún error en el proceso de login
 */
export class SigninSuccess implements Action {
  readonly type = SIGNIN_SUCCESS;
  constructor(public payload: {user: firebase.User}) {}
}

export class SignupError implements Action {
  readonly type = SIGNUP_ERROR;
  constructor(public payload: {error: any}) {}
}

/**
 * @description Acción que nos informa de que ha habido algún error en el proceso de login
 */
export class SignupSuccess implements Action {
  readonly type = SIGNUP_SUCCESS;
  constructor(public payload: {user: firebase.User}) {}
}

export class SetAppUser implements Action {
  readonly type = SET_APP_USER;
  constructor(public payload: AppUser) {}
}

/**
 * @description Acción que nos informa de que el proceso de loggin se ha completado, con éxito o no
 */
export class SigninProcessComplete implements Action {
  readonly type = SIGNIN_PROCESS_COMPLETE;
  constructor(public payload: {success: boolean}) {}
}

/**
 * @description El usuario ha realizado el registro
 */
export class Signup implements Action {
  readonly type = SIGNUP;
}

/**
 * @description El usuario ha realizado el login
 */
export class Signin implements Action {
  readonly type = SIGNIN;
}

/**
 * @description El usuario ha realizado el logout
 */
export class Logout implements Action {
  readonly type = LOGOUT;
}

/**
 * @description Se ha cambiado el valor del token del usuario activo
 */
export class SetToken implements Action {
  readonly type = SET_TOKEN;
  constructor(public payload: string) {}
}


/**
 * En este caso comprobamos que todas las clases exportadas en este archivo son instancias de '@ngrx/store/Action'
 *
 * así pues mediante la declaración 'type' establecemos varios alias alli donde se espere una 'AuthActionType'
 */
export type AuthActionTypes = Signin | SigninError | SetAppUser | SigninSuccess | Signup |
Logout | SetToken | TrySignup | TrySignin | SigninProcessComplete | SignupSuccess | SignupError;
