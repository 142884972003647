import { Injectable } from "@angular/core";
import { DebugService, DebugLevel } from "./debug.service";
import { Store } from "@ngrx/store";
import { AppState } from "app/store/reducers/app.reducer";
import { take } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable()
export class LangService {
    constructor(
        private debugService: DebugService,
        private store: Store<AppState>
    ) {}
    /**
     * @function getLanguageText
     * @description Recupera el texto en la lengua solicitada para los items recuperados de la database
     * @param texts
     * @param lang (optional)
     */
    public getLanguageText(texts: object, lang = '' ): string {
        if(!lang) lang = this.getActualLanguage();
        if(!texts[lang]) {
            this.debugService.debug(DebugLevel.ERROR, `En los textos no se ha encontrado [ ${lang} ]: `, texts);
            return '';
        }
        return texts[lang];
    }
    /**
     * @function getActualLanguage
     * @description Devuelve el valor guardado actualmente en el store para lang
     */
    public getActualLanguage(): any {
        let lang;
        this.store.select(state => state.utils.lang).pipe( take(1) ).subscribe(acualLang => lang = acualLang);
        return lang;
    }
}