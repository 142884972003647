import { AfterViewInit, Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, Input } from '@angular/core';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { MatBottomSheet } from '@angular/material';
import { BottomSheetShareComponent } from '../bottom-sheet-share/bottom-sheet-share.component';
import { NotificationService } from 'app/services/notifications.service';


@Component({
  selector: 'app-portfolio-carousel',
  templateUrl: './portfolio-carousel.component.html',
  styleUrls: ['./portfolio-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PortfolioCarouselComponent implements OnInit, AfterViewInit {

  public carouselOptions: NguCarouselConfig;

  @Input('backgroundGray') public backgroundGray;
  @Input() public lang: string;
  @Input() public items!: Array<any>;
  @Input() public bgImage: string;
  @Input() public sectionHeader: string;
  @Input() public sectionDescription: string;
  @Input() public options: any;

  constructor(
    private bottomSheet: MatBottomSheet,
    private _cdr: ChangeDetectorRef,
    private notification: NotificationService) {
  }

  openShareComponent(): void {
    // this.bottomSheet.open(BottomSheetShareComponent);
  }

  ngOnInit() {
    this.items.sort((a, b) => a.order - b.order)
    this.carouselOptions = {
      grid: { xs: 1, sm: 2, md: 3, lg: 3, all: 0 },
      slide: 2,
      speed: 400,
      interval: null,
      point: {
        visible: true
      },
      load: 2,
      touch: true,
      loop: false
    }
  }

  ngAfterViewInit() {
    this._cdr.detectChanges();
  }

}
