import { Component, OnInit } from "@angular/core";
import * as pageData from "./page.data";
import { Subject } from "rxjs";
import { Store } from "@ngrx/store";
import { AppState } from "app/store/reducers/app.reducer";
import { takeUntil } from "rxjs/operators";
import { MatDialogConfig, MatDialog } from "@angular/material";
import { SigninComponent } from "../signin/signin.component";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
  public lang: string;
  public ngDestroyed$ = new Subject();

  public texts: any = pageData.texts;

  constructor(
	  private store: Store<AppState>, 
	  private dialog: MatDialog
	) {
    this.store
      .select(state => state.utils.lang)
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((lang: string) => {
        this.lang = lang;
		  console.log('lang: ', this.lang)
      });
  }

  openSigninPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(SigninComponent, dialogConfig);
  }

  ngOnInit() {}
}
