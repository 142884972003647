import { Injectable } from "@angular/core";
import { Effect, Actions, ofType } from "@ngrx/effects";
import { Router } from "@angular/router";
import { FirebaseService } from "app/services/firebase.service";
import { NotificationService } from "app/services/notifications.service";
// actions
import * as productActions from './../actions/product.actions';
import { switchMap, map, withLatestFrom, catchError, tap } from "rxjs/operators";
import * as ProductInterfaces from "app/interfaces/product.interfaces";
import { ProductService } from "app/services/product.service";
import { Store } from "@ngrx/store";
import { DebugService, DebugLevel } from "app/services/debug.service";
@Injectable()
export class ProductEffects {
    // FETCH_PRODUCTS
    @Effect()
    fetchProducts = this.actions$.pipe(
        ofType(productActions.FETCH_PRODUCTS),
        map((action: productActions.FetchProducts) => {
            this.debugService.debug(DebugLevel.TRACE, `Product@Effects->FetchProducts / init: `, action.payload);
            return action.payload.params;
        }),
        switchMap((params) => {
            const type = params.type ? params.type : null;
            this.debugService.debug(DebugLevel.TRACE, `Product@Effects->FetchProducts`, type);
            return this.productService.fetchProducts(type).pipe(
                tap(_ => console.log('vamo bien 2')),
                map(data => ({ type: 'SET_PRODUCTS', payload: data }))
            );
        })
    );
    // TRY_ADD_PRODUCT
    @Effect()
    tryProductAdd = this.actions$.pipe(
        // Interceptamos solo un intento de crear usuario nuevo
        ofType(productActions.TRY_ADD_PRODUCT),
        // Filtramos el stream para avanzar en el chain solo con el payload
        map((action: productActions.TryAddProduct) => {
            this.debugService.debug(DebugLevel.TRACE, `Product@Effects->TryAddProduct / init: `, action.payload);
            return action.payload.product;
        }),
        switchMap((product: ProductInterfaces.Product) => {
            this.productService.addProduct(product);
            return [
                { type: 'ADD_PRODUCT', payload: { product } }
            ];
        }),
        catchError((err) => {
            this.debugService.debug(DebugLevel.ERROR, `Error in add product: `, err);
            return [];
        })
    );
    // TRY_UPDATE_PRODUCT
    @Effect()
    tryUpdateProduct = this.actions$.pipe(
        // Interceptamos solo un intento de crear usuario nuevo
        ofType(productActions.TRY_UPDATE_PRODUCT),
        // Filtramos el stream para avanzar en el chain solo con el payload
        map((action: productActions.TryUpdateProduct) => {
            this.debugService.debug(DebugLevel.TRACE, `Product@Effects->TryUpdateProduct / init: `, action.payload);
            return { index: action.payload.index, updatedProduct: action.payload.updatedProduct };
        }),
        switchMap(({ index, updatedProduct }) => {
            this.productService.updateProduct(updatedProduct);
            return [
                { type: 'UPDATE_PRODUCT', payload: { index, updatedProduct } }
            ];
        }),
        catchError((err) => {
            this.debugService.debug(DebugLevel.ERROR, `Error in add product: `, err);
            return [];
        })
    );
    // STORE_PRODUCTS
    @Effect()
    storeProducts = this.actions$.pipe(
        ofType(productActions.STORE_PRODUCTS),
        withLatestFrom(this.store.select('products')),
        switchMap(([action, state]) => {
            console.log('STORE_PRODUCTS - action: ', action);
            console.log('STORE_PRODUCTS - state: ', state);
            return [];
        })
    )
    constructor(
        // Un observable que va devolviendo las acciones emitidas por la app
        private actions$: Actions,
        private debugService: DebugService,
        private router: Router,
        private firebaseService: FirebaseService,
        private notificationService: NotificationService,
        private productService: ProductService,
        private store: Store<ProductInterfaces.LazyProductAppState>
    ) { }
}