/**
 * @description opciones de configuración para el componente
 */
export enum ImagePositions {
    right = 'right',
    left = 'left'
}
export interface Options {
    // Nos dice si el fondo estará fijo o no durante el scroll
    bgFixed?: boolean,
    bgPosition?: ImagePositions
}