import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppState } from 'app/store/reducers/app.reducer';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import * as TitleImageInterfaces from 'app/shared/components/titleimage/interfaces';
import { newItems } from './data';
import { DebugService, DebugLevel } from 'app/services/debug.service';

import * as UtilActions from "app/store/actions/utils.actions";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  public lang: string;
  public ngDestroyed$ = new Subject();

  public homeContent$: any = [];
  public newItems: any = newItems;

  public titleImageOptions: TitleImageInterfaces.Options = {
    bgFixed: true
  }

  constructor(
    private store: Store<AppState>,
    private debugService: DebugService
  ) {

  }

  ngOnInit() {
    this.store.select(state => state.utils.lang).pipe(
      takeUntil(this.ngDestroyed$)
    ).subscribe((lang: string) => {
      this.lang = lang;
    });

    this.store.select(state => state.content).pipe(
      map((state: any) => {
        this.debugService.debug(DebugLevel.TRACE, 'Home@init: Información recibida: ', state.content)
        return (state && state.content && state.content.home) ? state.content.home : null
      }
      ),
      takeUntil(this.ngDestroyed$)
    ).subscribe((homeData) => {
      if (homeData) {
        this.homeContent$ = homeData;
        this.debugService.debug(DebugLevel.INFO, 'Home info received, setting appReady');
        this.store.dispatch(new UtilActions.ReadySet({ ready: true }))
      }
    });
  }

  public ngOnDestroy() {
    this.ngDestroyed$.next();
  }

}
