import { Action } from "@ngrx/store";

export const TRY_LANG_SET = "TRY_LANG_SET";
export const LANG_SET = "LANG_SET";

export const TRY_READY_SET = "TRY_READY_SET";
export const READY_SET = "READY_SET";

export class TryLangSet implements Action {
    readonly type = TRY_LANG_SET;
    constructor(public payload: { lang: string }) { }
}

export class LangSet implements Action {
    readonly type = LANG_SET;
    constructor(public payload: { lang: string }) { }
}

export class ReadySet implements Action {
    readonly type = READY_SET;
    constructor(public payload: { ready: boolean }) { }
}

export type UtilsActionTypes = TryLangSet | LangSet | ReadySet;