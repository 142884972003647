import { SharedMaterialModule } from '../../shared-material.module';
import { CommonModule } from '@angular/common';
import { BgVideoComponent } from './bgvideo.component';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    CommonModule,
    SharedMaterialModule
  ],
  declarations: [BgVideoComponent],
  exports: [BgVideoComponent]
})
export class BgVideoModule { }
