import { Injectable } from "@angular/core";
import { Effect, Actions, ofType } from "@ngrx/effects";
import { FirebaseService } from "app/services/firebase.service";
// actions
import * as contentActions from './../actions/content.actions';
import { switchMap, map, take } from "rxjs/operators";
import { DebugService, DebugLevel } from "app/services/debug.service";

@Injectable()
export class ContentEffects {
    // FETCH_CONTENT
    @Effect()
    fetchContent = this.actions$.pipe(
        ofType(contentActions.FETCH_CONTENTS),
        switchMap(() => {
            const pageContentRef = this.firebaseService.getReference('data');
            // TODO: en producción no será necesario estar escuchando todo el tiempo a los cambios de la database
            return pageContentRef.valueChanges().pipe(
                take(1),
                map(data => {
                    // @ts-ignore
                    data[0].home.maquines_home.items = data[0].home.maquines_home.items.filter(item => {
                        return item && item !== 'empty';
                    });
                    this.debugService.debug(DebugLevel.TRACE, `Page data loaded`, data);
                    return ({ type: 'SET_CONTENTS', payload: data })
                }
                )
            );
        })
    );

    // TRY_UPDATE_ITEM
    @Effect()
    updateItem = this.actions$.pipe(
        ofType(contentActions.TRY_UPDATE_ITEM),
        switchMap(async (action: any) => {
            this.debugService.debug(DebugLevel.TRACE, `Intentamos hacer update del item`, action.payload.itemIndex);
            const pageContentRef = this.firebaseService.getReference('data');
            const routeToUpdate = 'pages/' + action.payload.page + '/' + action.payload.section + "/items/" + action.payload.itemIndex + "/";
            action.payload.updatedContent.changed = false;
            await pageContentRef.update(routeToUpdate, action.payload.updatedContent);
            return { type: 'UPDATE_CONTENT' }
        })
    );

    // TRY_REMOVE_ITEM
    @Effect()
    deleteItem = this.actions$.pipe(
        ofType(contentActions.TRY_REMOVE_CONTENT),
        switchMap(async (action: any) => {
            this.debugService.debug(DebugLevel.TRACE, `Intentamos hacer REMOVE del item`, action.payload.itemIndex);
            const pageContentRef = this.firebaseService.getReference('data');
            const routeToUpdate = 'pages/' + action.payload.page + '/' + action.payload.section + "/items/" + action.payload.itemIndex + "/";
            console.log('routeToUpdate: ', routeToUpdate)
            await pageContentRef.remove(routeToUpdate);
            return { type: 'REMOVE_CONTENT' }
        })
    );

    // TRY_UPDATE_SECTION
    @Effect()
    updateSection = this.actions$.pipe(
        ofType(contentActions.TRY_UPDATE_SECTION),
        switchMap(async (action: any) => {
            this.debugService.debug(DebugLevel.TRACE, `Intentamos hacer update de la sección`, action.payload.section);
            const pageContentRef = this.firebaseService.getReference('data');
            const routeToUpdate = 'pages/' + action.payload.page + '/' + action.payload.section + "/items/";
            action.payload.updatedContent.changed = false;
            await pageContentRef.update(routeToUpdate, action.payload.updatedContent);
            return { type: 'UPDATE_SECTION', payload: { page: action.payload.page, section: action.payload.section, updatedSection: action.payload.updatedContent } }
        })
    );

    // TRY_ADD_CONTENT
    @Effect()
    addContent = this.actions$.pipe(
        ofType(contentActions.TRY_ADD_CONTENT),
        switchMap(async (action: any) => {
            this.debugService.debug(DebugLevel.TRACE, `Añadiendo nuevos items:`, action.payload);
            const pageContentRef = this.firebaseService.getReference('data');
            const routeToUpdate = 'pages/' + action.payload.page + '/' + action.payload.section + "/items/";
            await pageContentRef.set(routeToUpdate, action.payload.updatedItems);
            return { type: 'UPDATE_CONTENT' }
        })
    );

    constructor(
        private actions$: Actions,
        private debugService: DebugService,
        private firebaseService: FirebaseService
    ) { }
}