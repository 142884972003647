import { Product, DatabaseProduct } from "app/interfaces/product.interfaces";
import { Action } from "@ngrx/store";

// Acciones REST
export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const TRY_EDIT_PRODUCT = "TRY_EDIT_PRODUCT";
export const TRY_ADD_PRODUCT = "TRY_ADD_PRODUCT";
export const TRY_REMOVE_PRODUCT = "TRY_REMOVE_PRODUCT";
export const STORE_PRODUCTS = "STORE_PRODUCTS";
export const TRY_UPDATE_PRODUCT = "TRY_UPDATE_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const ADDED_PRODUCT = "ADDED_PRODUCT";

export class SetProducts implements Action{
    readonly type = SET_PRODUCTS;
    constructor(public payload: {products: Product[]}) {}
}
/**
 * @payload {params}
 */
export class FetchProducts implements Action{
    readonly type = FETCH_PRODUCTS;
    constructor(public payload: {params: any}) {}
}
export class TryUpdateProduct implements Action{
    readonly type = TRY_UPDATE_PRODUCT;
    constructor(public payload: {index: number, updatedProduct: DatabaseProduct}) {}
}
/**
 * @payload {product}
 */
export class TryAddProduct implements Action{
    readonly type = TRY_ADD_PRODUCT;
    constructor(public payload: {product: Product}) {}
}

export class TryRemoveProduct implements Action{
    readonly type = TRY_REMOVE_PRODUCT;
    constructor(public payload: {id: string}) {}
}
/**
 * @payload {product}
 */
export class UpdateProduct implements Action{
    readonly type = UPDATE_PRODUCT;
    constructor(public payload: {product: Product}) {}
}

export class AddProduct implements Action{
    readonly type = ADD_PRODUCT;
    constructor(public payload: {product: Product}) {}
}

export class ProductAdded implements Action{
    readonly type = ADDED_PRODUCT;
    constructor(public payload: {product: Product}) {}
}

export class RemoveProduct implements Action{
    readonly type = REMOVE_PRODUCT;
    constructor(public payload: {id: string}) {}
}

export class StoreProducts implements Action {
    readonly type = STORE_PRODUCTS;
  }

export type ProductActions = ProductAdded | SetProducts | RemoveProduct |  AddProduct | UpdateProduct | TryRemoveProduct | TryAddProduct | TryUpdateProduct | FetchProducts;