import {
  Directive,
  ElementRef,
  Renderer2,
  HostListener,
  HostBinding,
  Input
} from "@angular/core";

@Directive({
  selector: "[selectItemAddClass]"
})
export class SelectItemAddClassDirective {
  public _elementClass: string[] = [];

  @Input("class") class: string;

  constructor(private renderer: Renderer2, private hostElement: ElementRef) {}

  @HostListener("click") elementClick() {
    this.renderer.addClass(this.hostElement.nativeElement, "clicked");
  }
}
