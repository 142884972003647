import { Component, OnInit, Input } from '@angular/core';
import { NotificationService } from 'app/services/notifications.service';

@Component({
  selector: 'app-logos',
  templateUrl: './logos.component.html',
  styleUrls: ['./logos.component.scss']
})
export class LogosComponent implements OnInit {
  @Input() lang: string;
  @Input() items!: Array<any>;
  @Input() public bgImage: string;
  @Input() public title: string;
  @Input() public text: string;

  constructor(private notifications: NotificationService) { }

  ngOnInit() { }

  public provisional_alert() { this.notifications.warn('Página web en construcción') }

}
