import { map, concatMap, first, switchMap, tap } from 'rxjs/operators';
import { NotificationService } from './notifications.service';
import { StoreService } from './store.service';
import { LocalStorageService } from './localstorage.service';
import * as authActions from './../store/actions/auth.actions';
import * as firebase from 'firebase/app';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireDatabase, AngularFireAction} from '@angular/fire/database';
import { Store } from '@ngrx/store';
import { Injectable, OnInit } from '@angular/core';
// firebase auth
import { AngularFireAuth } from '@angular/fire/auth';
import { from, Observable, BehaviorSubject } from 'rxjs';
import { firebasePayload } from 'app/types/auth.types';
import { Unsubscribe } from 'firebase/app';
import { AppState } from 'app/store/reducers/app.reducer';
import { fromPromise } from 'rxjs/internal/observable/fromPromise';
import { Product, DatabaseProduct } from 'app/interfaces/product.interfaces';
import { DebugService, DebugLevel } from './debug.service';
import { TranslateService } from "@ngx-translate/core";
@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  public fbAuthUnsubscriber: Unsubscribe;
  public items$: any;

  constructor (
    private readonly firestore: AngularFirestore,
    private store: Store<AppState>,
	  private translate: TranslateService,
    private storeService: StoreService,
    private localstorageService: LocalStorageService,
    private fireAuth: AngularFireAuth,
    private notificationService: NotificationService,
    private debugService: DebugService,
    private angularFireDatabase: AngularFireDatabase
  ) {
    this.subscribeToAuthChanges();
  }
  /**
   * @function getCollectionReference
   * @description
   */
  public getCollectionReference(name: string): AngularFirestoreCollection<any> {
    return this.firestore.collection(name);
  }
  /**
   * @function getCollectionValueChanges
   * @description Se usa únicamente para mostrar información en alguna vista, solo contiene los campos de los docs
   */
  public getCollectionValueChanges(dbReference: AngularFirestoreCollection<any>): Observable<any> {
    // .valueChanges() is simple. It just returns the  JSON data without metadata. If you need the doc.id() 
    // in the value you must persist it your self or use .snapshotChanges() instead. See the addItem()
    // method below for how to persist the id with valueChanges()
    return dbReference.valueChanges();
  }
  /**
   * @description Devuelve contenido más enriquecido de la collection
   * @returns {exists, ref, metadata, id, data , get()}
   */
  public getCollectionSnapshotChanges(dbReference: AngularFirestoreCollection<any>): Observable<any> {
    // TODO dar salida a otros tipos de info: tipo de acción realizada (add, remove, edit, ...), oldIndex, etc...
    return dbReference.snapshotChanges().pipe(
      map(products => products.map(product => {
        const data = product.payload.doc.data() as Product;
        const id = product.payload.doc.id;
        return { id, ...data };
      }))
    );
  }
  /**
   * @description Devuelve la referencia a una base de datos 'RealTime' de firebase
   */
  public getReference(name: string) {
    return this.angularFireDatabase.list('/' + name);
  }

  /**
   * @description Persist a document id
   */
  public addItem(item, ref) {
    this.debugService.debug(DebugLevel.TRACE, 'FirebaseService->addItem / init: ', item );
    const id = this.firestore.createId();
    const newItem: DatabaseProduct = { id, ...item };
    const itemsCollection = this.getCollectionReference(ref);
    itemsCollection.doc(id).set(newItem);
    this.debugService.debug(DebugLevel.TRACE, 'FirebaseService->addItem / end: ', id );
  }

  public updateItem(item, ref) {
    this.debugService.debug(DebugLevel.TRACE, 'FirebaseService->updateItem / init: ', item );
    this.getCollectionReference(ref).doc(item.id).update(item);
    this.debugService.debug(DebugLevel.TRACE, 'FirebaseService->addItem / end: ', '');
  }

  public unsubscribeAuth() {
    this.unsubscribeAuth();
  }

  public createNewUser(authData: firebasePayload) {
    // Usamos from para convertir la Promise en Observable
    return from(this.fireAuth.auth.createUserWithEmailAndPassword(
      authData.email,
      authData.password
    ));
  }

  public getFirebaseToken() {
    return from(this.fireAuth.auth.currentUser.getIdToken());
  }

  public signinUser(loginParams: firebasePayload): Observable<firebase.auth.UserCredential> {
    return from(this.fireAuth.auth.signInWithEmailAndPassword(
      loginParams.email,
      loginParams.password
    ));
  }

  public logoutUser(): Observable<void> {
    return from(this.fireAuth.auth.signOut());
  }

  private subscribeToAuthChanges() {
    this.fbAuthUnsubscriber = this.fireAuth.auth.onAuthStateChanged(
    (user) => {
      if (user !== null) {
        fromPromise(user.getIdToken(true)).pipe(
          concatMap((token: string): Observable<AppState> => {
            // Refrescamos el token de firebase
            this.store.dispatch(new authActions.SetToken(token));
            // Guardamos el token en el localstorage
            this.localstorageService.setItem('token', token);
            return this.storeService.getActualAppState().pipe(first());
          })
        ).subscribe((state: AppState) => {
            // Nos aseguramos de que no llamamos a la acción signin dos veces
            if (!state.auth.authenticated) {
                this.store.dispatch(new authActions.SigninSuccess({user: user}));
                console.log(' ***********  notify 2')
				        this.notificationService.success(this.translate.instant('core.COOKIES'));
            }
        });
        // Comunicamos a toda la app que el usaurio ya está logeado
      } else {
        console.log(' ***********  notify 1')
		  this.notificationService.success(this.translate.instant('core.COOKIES'));
      }
    },
    (err) => {
      console.warn('Error in firebase authState: ', err);
    });
  }

  public doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.fireAuth.auth
      .signInWithPopup(provider)
      .then(res => {
        resolve(res);
      })
      .catch((err) => {
        console.error('Error al autenticar con google mediante popup: ', err);
      });
    });
}
}
