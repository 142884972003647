import { Component, OnInit } from "@angular/core";
import { environment } from "./../environments/environment";
import { Store } from "@ngrx/store";
import { AppState } from "./store/reducers/app.reducer";
import { Observable } from "rxjs/Observable";

@Component({
  selector: "app-web",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  public appReady: Observable<boolean> = this.store.select(
    appState => appState.utils.appReady
  );
  private environment = environment;
  /**
   * Necesitamos instanciar aquí los servicios que queremos que se generen desde el minuto 0 en nuestra app
   */
  constructor(private store: Store<AppState>) {}

  ngOnInit() {}
}
