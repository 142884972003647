import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentItemsComponent } from './content-items.component';
import { SharedMaterialModule } from 'app/shared/shared-material.module';

@NgModule({
  declarations: [ContentItemsComponent],
  imports: [
    CommonModule,
    SharedMaterialModule
  ],
  exports: [ContentItemsComponent]
})
export class ContentItemsModule { }
