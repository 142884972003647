import { NotificationService } from './notifications.service';
import { Injectable } from '@angular/core';

/**
 * Si la app está en el nivel ERROR el usuario solo verá los mensajes de error
 * Si la app se encuentra en el nivel DEBUG se mostrarán los mensajes de DEBUG, INFO y ERROR
 * ...
*/
export enum DebugLevel {
    TRACE,
    DEBUG,
    INFO,
    ERROR
}
@Injectable()
export class DebugService {
    public appLevel: DebugLevel;

    constructor(private notificationsService: NotificationService) {}

    public setDebugLevel(level: DebugLevel) {
        this.appLevel = level;
    }

    public getDebugLevel() {
        return this.appLevel;
    }

    /**
     * @description Esta función sirve para mostrar mensajes en función del nivel en el que se encuentre el sistema
    */
    public debug = (level: DebugLevel, message:string, object: any = null) => {
        if (level >= this.appLevel) {
            switch(level) {
                case 0:
                // TRACE
                    console.log('-- TRACEPOINT : ' + message, object);
                    break;
                case 1:
                // DEBUG
                    console.log('** DEBUG : ' + message, object);
                    break;
                case 2:
                // INFO
                    console.warn('__INFO: ' + message, object);
                    break;
                case 3:
                // ERROR
                    console.error('ERROR: ' + message, object);
                default:
                    console.log('MESSAGE: ' + message, object);
                    break;
            }
        }
    }
    /**
     * Función que envia una doble notificación al usuario. Un popup informando de la descripción del error
     *   y un mensaje en la consola con los detalles recibidos de ese error
     */
    public notifyError(trace, description, err) {
        this.debug(DebugLevel.TRACE, trace);
        this.debug(DebugLevel.ERROR, err);
        this.notificationsService.error(description + ' Para mas información consultar la consola.');
    }

}







