import { Effect, Actions, ofType } from "@ngrx/effects";
import * as UtilActions from './../actions/utils.actions';
import { switchMap, map } from "rxjs/operators";
import { DebugService, DebugLevel } from "app/services/debug.service";
import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";
@Injectable()
export class UtilsEffects {

    constructor(
        private actions$: Actions,
        private debugService: DebugService,
        private translate: TranslateService
    ) {}
    /**
     * TRY_LANG_SET
     */
    @Effect() validateLang = this.actions$.pipe(
        ofType(UtilActions.TRY_LANG_SET),
        map((action: UtilActions.TryLangSet) => {
            this.debugService.debug(DebugLevel.TRACE, `Utils@Effects->validateLang / init: `, action.payload.lang);
            return action.payload.lang;
        }),
        switchMap((lang: string) => {
            // TODO realizar comprobaciones de lenguaje válido
            this.translate.use(lang);
            return [{type: 'LANG_SET' , payload: {lang}}];
        })
    )
}