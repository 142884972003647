import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import {
  MatSidenavModule,
  MatListModule,
  MatTooltipModule,
  MatOptionModule,
  MatSelectModule,
  MatMenuModule,
  MatSnackBarModule,
  MatGridListModule,
  MatToolbarModule,
  MatIconModule,
  MatButtonModule,
  MatRadioModule,
  MatCheckboxModule,
  MatCardModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatBadgeModule,
  MatTabsModule,
  MatBottomSheetModule,
  MatSlideToggleModule,
  MatFormFieldModule,
  MatInputModule,
} from '@angular/material';
import { MatExpansionModule } from '@angular/material/expansion'

@NgModule({
  imports: [
    MatSidenavModule,
    MatListModule,
    MatTooltipModule,
    MatOptionModule,
    MatSelectModule,
    MatMenuModule,
    MatSnackBarModule,
    MatGridListModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatDialogModule,
    MatBadgeModule,
    MatTabsModule,
    MatBottomSheetModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule],
  exports: [
    MatSidenavModule,
    MatListModule,
    MatTooltipModule,
    MatOptionModule,
    MatExpansionModule,
    MatSelectModule,
    MatMenuModule,
    MatSnackBarModule,
    MatGridListModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatCheckboxModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatDialogModule,
    MatBadgeModule,
    MatTabsModule,
    MatBottomSheetModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule
  ],
  declarations: []
})
export class SharedMaterialModule { }
