import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DebugService, DebugLevel } from "./services/debug.service";
import { environment } from "./../environments/environment";
import { Store } from "@ngrx/store";
import * as UtilActions from "app/store/actions/utils.actions";
import * as ContentActions from "app/store/actions/content.actions";
import { AppState } from "./store/reducers/app.reducer";

@Component({
    selector: 'app-root',
    template: `<router-outlet></router-outlet>`
})
export class RootComponent {
    /**
   * Necesitamos instanciar aquí los servicios que queremos que se generen desde el minuto 0 en nuestra app
   */
    constructor(
        private debugService: DebugService,
        private translate: TranslateService,
        private store: Store<AppState>
    ) {
        // this language will be used as a fallback when a translation isn't found in the current language
        // TODO cambiar a variables de entorno o config globales
        this.translate.setDefaultLang("en");

        // Especificamos el nivel de debug para ver los mensages de log
        // TRACE, DEBUG, INFO, ERROR
        // TODO cambiar a variables de entorno o config globales
        this.debugService.setDebugLevel(DebugLevel.TRACE);

        // GLOBAL SUBSCRIPTIONS:
        // select puede usar funciones o strings para recuperar variables de la store
        // Nos subscribimos a los cambios de lenguaje
        this.store
            .select(appState => appState.utils.lang)
            .subscribe((lang: string) => {
                this.debugService.debug(DebugLevel.INFO, "Store lang: ", lang);
            });

        // Prueba con realtime database
        // itemsRef.update('b', { size: 'small' });

        // Recuperamos todo el contenido (textos/imágenes) de la web
        this.store.dispatch(new ContentActions.FetchContents());

        // Recuperamos el idioma del navegador del usuario
        let userLang: string = this.translate.getBrowserLang();
        // the lang to use, if the lang isn't available, it will use the current loader to get them
        this.store.dispatch(new UtilActions.TryLangSet({ lang: userLang }));
    }
}