// core
import { ActionReducerMap } from '@ngrx/store';
// interfaces usadas
// reducers
import { authReducer } from './auth.reducers';
import { AuthState } from 'app/interfaces/auth.interfaces';
import { ProductState } from 'app/interfaces/product.interfaces';
import { productReducer } from './product.reducer';
import { UtilsState } from 'app/interfaces/utils.interfaces';
import { utilsReducer } from './utils.reducer';
import { contentReducer } from './content.reducer';
import { Content } from 'app/interfaces/content.interfaces';

/**
 * Definimos el estado global de toda la app
 *
 * En esta definición deberemos incluir todos los estados que querremos modificar/recuperar en nuestra app
 */
export interface AppState {
  content: Content,
  utils: UtilsState;
  auth: AuthState;
  product: ProductState;
}

/**
 * Definimos todos los reducers que se usaran en nuestra app
 *
 * Cada reducer deberá devolver el estado entero de la app posteriormente a haberlo modificado
 */
export const reducers: ActionReducerMap<AppState> = {
  content: contentReducer,
  auth: authReducer,
  product: productReducer,
  utils: utilsReducer
}
