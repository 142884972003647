// Acciones
import * as AuthActions from '../actions/auth.actions';
// Interfaces usadas
import { AuthState } from 'app/interfaces/auth.interfaces';

/**
 * Definimos el estado inicial para 'Auth'
 */
const initialState: AuthState = {
  user: null,
  token: null,
  authenticated: false
};
/**
 * Gestionamos los cambios de estado para 'Auth' según las acciones permitidas:
 *
 * - SIGNUP, SIGNIN -> Simplemente cambia el authenticated a 'true'
 * - LOGOUT -> Vuelve el campo authenticated a 'false'
 */
export function authReducer(state = initialState, action: AuthActions.AuthActionTypes) {
  switch(action.type) {
    case (AuthActions.SIGNUP):
    case (AuthActions.SIGNIN):
      return {
        ...state,
        authenticated: true
      };
    case (AuthActions.LOGOUT):
      return {
        ...state,
        authenticated: false,
        token: null,
        user: null
      };
    case (AuthActions.SET_TOKEN):
      return {
        ...state,
        token: action.payload
      };
    case (AuthActions.SET_APP_USER):
      console.warn('reducer user received: ', action.payload);
      return {
        ...state,
        authenticated: true,
        user: action.payload
      };
      break;
    default:
      return state;
  }
}
